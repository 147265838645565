import React from 'react';
import { Provider } from 'react-redux';
import { Link } from 'gatsby';
import { PrismicProvider } from '@prismicio/react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { repositoryConfigs } from './src/utils/prismicPreviews';
import createStore from './src/state/createStore';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    const store = createStore();

    return (
        <Provider store={store}>
            <PrismicProvider internalLinkComponent={({ href, ...props }) => <Link to={href} {...props} />}>
                <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>{element}</PrismicPreviewProvider>
            </PrismicProvider>
        </Provider>
    );
};
