import { createStore as reduxCreateStore } from 'redux';

const reducer = (state, action) => {
    if (action.type === `CHECK_STATUS`) {
        return Object.assign({}, state, {
            blocked: action.blocked,
        });
    }
    return state;
};

const initialState = { blocked: false };

const createStore = () => reduxCreateStore(reducer, initialState);

export default createStore;
